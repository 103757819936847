import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Link from "gatsby-link"
import Bio from '../components/ui/bio'
import Layout from "../layouts/layout-scroll"
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

const Item = (props) => (
  <>
  <article style={{ height: props.small ? '8rem' : '15rem' }} key={props.node.fields.slug}>
    <Link
        to={props.node.fields.slug}
        style={{
          textDecoration: `none`,
          color: `inherit`,
        }}
      >
      <div style={{
        position: 'relative',
        width: '100%',
        height: '100%'
       }}>
        <Image
          fluid={props.node.frontmatter.cover.childImageSharp.fluid}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: `0.4em`,
          }}
          />
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              bottom: 0,
              left: 0,
              background: 'linear-gradient(transparent, 70%, #333a)',
              borderRadius: `0.5em`,
            }}
          />
          <div style={{
            position: 'absolute',
            bottom: '1rem',
            left: '1rem',
          }} >
            <h3 style={{ display: 'inline', color: '#efefef' }}>{props.node.frontmatter.title}</h3>
            <span style={{ margin: 0, color: '#999' }}> - {props.node.frontmatter.date}</span>
          </div>
        </div>
     </Link>
   </article>
  </>
)

export default ({data}) => {
  return (
  <Layout>
    <Helmet htmlAttributes={{ class:'ride fixed' }} />
    <SEO title="Ride"
      description={data.site.siteMetadata.descriptions.ride} />
    <>
      <h1>Ride</h1>
      <p style={{ textAlign: 'center' }}>I ride (mountain) bikes since I'm a little kid</p>
      <div className="ride-grid" >
        <div className="left" >
          <p>Current rides:</p>
          {data.allMarkdownRemark.edges.filter(item => !item.node.frontmatter.retired).map(({node}, idx) =>
            <Item node={node} />
          )}
        </div>
        <div className="right" >
          <p>Retired:</p>
          <div className="content" >
          {data.allMarkdownRemark.edges.filter(item => item.node.frontmatter.retired).map(({node}, idx) =>
            <Item node={node} small={true} />
           )}
           </div>
        </div>
      </div>
      <p style={{ textAlign: 'center', marginTop: '1rem' }}>
        You can check out my <a href={"https://www.strava.com/athletes/" + data.site.siteMetadata.social.strava} target="_blank" rel="noopener noreferrer">Strava profile</a>
      </p>
    </>
  </Layout>
  )
}

export const query = graphql`
  query RideQuery {
    site {
      siteMetadata {
        title,
        subtitle,
        author,
        social {
          twitter,
          instagram,
          gitlab,
          strava
        },
        descriptions {
          ride
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "bike"}, visible: { eq: true}}}) {
      totalCount
      edges {
        node {
          frontmatter {
            type
            title
            retired
            date(formatString: "YYYY")
            cover {
              childImageSharp {
                fixed {
                  src
                  srcSet
                  width
                  height
                }
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
